
<template>
    <div>
        <div class="time_list">
            <div 
                class="item item_head grid" 
                :style="`grid-template-columns: ${pageConfig.gridColumns};`">
                <div 
                    v-for="col in pageConfig.tableInfo" 
                    :key="col.field" 
                    class="col"
                    :class="col.class ? col.class : ''">
                    {{ col.headerName }}
                </div>
            </div>
            <div 
                v-for="item in timeList.results"
                :key="item.id"
                :style="`grid-template-columns: ${pageConfig.gridColumns};`"
                class="item grid">
                <div 
                    v-for="col in pageConfig.tableInfo" 
                    :key="col.field" 
                    class="col"
                    :class="col.class ? col.class : ''">
                    <template v-if="col.field === 'work_type'">
                        <template v-if="item.work_type">
                            {{ item.work_type.name }}
                        </template>
                    </template>
                    <template v-if="col.field === 'description'">
                        <a-popover 
                            v-if="descLength(item.description)"
                            :getPopupContainer="getPopupContainer2">
                            <template slot="content">
                                <div style="max-width: 400px;">
                                    {{ item.description }}
                                </div>
                            </template>
                            <span>
                                {{ descSubstr(item.description) }}
                            </span>
                        </a-popover>
                        <span v-else>
                            {{ descSubstr(item.description) }}
                        </span>
                    </template>
                    <Profiler 
                        v-if="col.field === 'author'" 
                        :user="item.author" />
                    <template v-if="col.field === 'hours'">
                        {{ item.hours }}
                    </template>
                    <template v-if="col.field === 'measure_unit'">
                        <template v-if="item.measure_unit">
                            {{ item.measure_unit.name }}
                        </template>
                    </template>
                    <template v-if="col.field === 'date'">
                        <template v-if="item.date">
                            {{ $moment(item.date).format('DD.MM.YYYY') }}
                        </template>
                    </template>
                    <template v-if="col.field === 'actions'">
                        <a-dropdown 
                            v-if="pageConfig.listActions && user && item.author && user.id === item.author.id" 
                            :getPopupContainer="getPopupContainer2">
                            <a-button
                                type="link" 
                                icon="menu" />
                            <a-menu slot="overlay">
                                <a-menu-item 
                                    v-if="pageConfig.listActions.editAction" 
                                    @click="$emit('editTime', item)">
                                    Редактировать
                                </a-menu-item>
                                <template v-if="pageConfig.listActions.deleteAction" >
                                    <a-menu-divider />
                                    <a-menu-item
                                        class="text_red" 
                                        @click="$emit('deleteTime', item)">
                                        Удалить
                                    </a-menu-item>
                                </template>
                            </a-menu>
                        </a-dropdown>
                    </template>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    props: {
        pageConfig: {
            type: Object,
            required: true
        },
        timeList: {
            type: Object,
            required: true
        },
        user: {
            type: Object,
            required: true
        },
        task: {
            type: Object,
            required: true
        }
    },
    methods: {
        getPopupContainer() {
            return document.querySelector('.work_time_form')
        },
        getPopupContainer2() {
            return document.querySelector('.task_body_wrap')
        },
        descSubstr(text) {
            if(text && text.length > 60)
                return text.substr(0, 60) + '...'
            else
                return text
        },
        descLength(text) {
            if(text && text.length > 60)
                return true
            else
                return false
        },
    }
}
</script>